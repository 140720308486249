.box-border-progress {
  position: relative;
  background-color: black;
  width: 90%;
  top: 1px;
  left: 0;
  right: 0;
  min-height: 6px;
  margin: 0 auto;
  border-radius: 5px;
}
.course-card {
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.card-img {
  max-width: 100%;
  height: 150px;
  background-size: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
