.banner-curso {
  width: 100%;
  height: 450px;
}

.detail-course-container {
  background-color: var(--blue-merkademia);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0rem 0%;
  height: 100%;
  background-color: #212735;
  transform: translateY(85px);
  overflow-y: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.course-title-row {
  color: #fff;
  font-size: 20px;
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
}

.group-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 1rem 0;
  color: #7d7d7d;
  font-size: 20px;
  padding: 1rem 0 0 1rem;
}

.group-icon {
  padding-right: 0.8rem;
}

@media screen and (max-width: 1200px) {
  .summary-html {
    height: 85%;
  }
  .embed-iframe {
    height: 95%;
  }
  .detail-course-container {
    overflow: scroll;
  }
}

.tit-detail-course {
  color: #68b7ca;
  padding-bottom: 3rem;
  font-size: 30px;
}

.btn-evaluacion {
  font-family: Pop-Bold;
  font-size: 16px;
  color: #fff;
  background-color: #006b95;
  border-radius: 25px;
  border: 0;
  padding: 20px 0 16px 0;
  width: 100%;
  margin-top: 10px;
}

.btn-evaluacion:hover {
  background-color: #69b7cb;
}

.tabs-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;
}

.btn-tab-course {
  font-family: Pop-Regular;
  font-size: 13px;
  color: #b2b2b2;
  background-color: #15171f;
  border-radius: 25px;
  border: 1px solid #454c63;
  padding: 1.3rem 1rem;
  margin: 0.5rem;
  width: 47%;
}

.btn-tab-course-2 {
  font-family: Pop-Regular;
  font-size: 13px;
  color: #7d7d7d;
  background-color: #15171f;
  border-radius: 25px;
  border: 1px solid #b2b2b2;
  padding: 1.3rem 1rem;
  margin: 0.5rem;
  width: 30%;
}

.btn-tab-course:hover {
  background-color: #69b7cb;
  border: 0;
}

.content-container {
  padding: 0 0 0 0;
  background-color: #15171f;
  margin: 0 0 0 0;
  overflow-y: scroll;
}

@media screen and (max-width: 1800px) {
  .btn-tab-course,
  .btn-tab-course-2 {
    width: 100%;
  }
}

@media screen and (max-width: 820px) {
  .banner-curso {
    height: 100px;
  }
  .btn-tab-course,
  .btn-tab-course-2 {
    width: 100%;
    font-size: 13px !important;
    padding: 1rem !important;
  }
}
