.eslogan {
  margin-left: auto;
  margin-right: 0;
}

.container-logo-login {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
}

/* .logo-login{
  width: 100%;
} */
