.content-mobile {
  display: none;
  position: absolute;
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 5px;
  transition: 0.5s;
  border-left: 1px solid grey;
  z-index: 900;
  background-color: #212735;
  /* background-color: #fff; */
  top: -5000px;
  left: 0;
  scroll-behavior: smooth;
  overflow-y: hidden;
}
.container-mobile {
  position: relative;
  height: calc(100vh - 85px);
  overflow-y: scroll;
}
@media screen and (max-width: 1024px) {
  .content-mobile {
    display: unset;
  }
  .content-container {
    border-radius: 20px;
    overflow-y: hidden;
    /* height: 100%; */
    height: auto;
    padding-bottom: 150px;
    margin-top: 1rem;
    position: relative;
  }
}
