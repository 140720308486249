.sidebar-container-messages {
  position: fixed;
  width: 320px;
  right: -350px;
  height: 100vh;
  background-color: #d6eef5;
  z-index: 1001;
  transition: 0.6s;
  display: flex;
  flex-direction: column;
  justify-content: top;
  padding: 0px 0 120px 0;
  top: 0;
  bottom: 0;
}

.top {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20%;
}

.top-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 1.5rem;
}

.top-content img {
  width: 25px;
}

.avatar {
  width: 100px !important;
}

.center {
  height: 60%;
}

.center-content {
  padding: 1rem;
}

.center-content ul {
  list-style: none;
}
.center-content ul li {
  font-size: 18px;
  color: #fff;
  display: flex;
  align-items: flex-start;
}
.center-content ul li img {
  margin-right: 1.2rem;
  width: 20px;
  margin-top: 0.2rem;
}
.bottom {
  height: 20%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: flex-end;
}

.bottom div {
  display: flex;
  justify-content: center;
}

.burger-messages {
  transition: 0.2s;
}

.burger-messages:hover {
  transform: scale(1.05);
  opacity: 0.8;
}

.msg-btn-desk {
  position: fixed;
  cursor: pointer;
  bottom: 20px;
  right: 20px;
  background-color: #0079ac;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.msg-badge {
  position: absolute;
  background-color: red;
  color: #fff;
  width: 20;
  height: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 0;
  right: 0;
}
.close-btn-msg {
  display: none;
  width: 25px;
  height: 25px;
  margin: 0.5rem 0 0.5rem auto;
  /* position: absolute;
  right: 5%;
  top: 2%; */
}

@media screen and (max-width: 1024px) {
  .msg-btn-desk {
    display: none !important;
  }
  .close-btn-msg {
    display: unset;
  }
}
