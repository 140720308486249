.noti-title {
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
}

.noti-tabContainer {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  padding: 1rem 0;
  background-color: rgb(0, 121, 172);
  margin: 0 !important;
}

.notification-medal {
  position: absolute;
  top: -7px;
  right: -7px;
  background-color: red;
  color: #fff;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 0.3s;
}

.notification-icon {
  font-size: 28px !important;
  transition: 0.3s !important;
}
.noti-mob-text {
  display: none;
}

@media screen and (max-width: 1023px) {
  .notification-icon {
    margin-right: 0.7rem;
    padding-right: 0.1rem;
  }
  .noti-mob-text {
    display: inline-block;
  }
  .notification-medal {
    right: -17px;
  }
}
